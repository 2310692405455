define(['app', 'accessibilityFocusHelper'], (app, accessibilityFocusHelper) => {

  const productReviewStars = () => {
    const component = {};

    const _config = {
      westendHeader: '.westendHeader',
      productReviewsComponentSelector: '[data-component=productReviews]',
      productReviewStars: '[data-component=productReviewStars]',
      athenaProductReviews: '[data-component=athenaProductReviews]',
      subscribeChannels: {
        productReviewStars: 'productReviewStars/update',
      },
      productReviewsSummaryTitle: '#productReviews-summaryTitle',
      
      svgLinearGradient: 'linearGradient',
      svgPath: 'path'
    };

    const _init = (element, userDriven) => {
      component.element = element;
      const productReview = document.querySelector(component.config.productReviewsComponentSelector);
      const athenaProductReview = document.querySelector(component.config.athenaProductReviews);
      component.productReviewsComponent = productReview || athenaProductReview;
      component.reviewTitle = component.productReviewsComponent.querySelector(component.config.productReviewsSummaryTitle);

      if (component.productReviewsComponent !== null) {
        component.element.addEventListener('click', component.scrollToReviewsComponent);
      }

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.productReviewStars, component.updateReviewStars);
      }

    };

    const _scrollToReviewsComponent = () => {
      const westendHeader = document.querySelector(component.config.westendHeader);
      app.element.scrollTo(component.productReviewsComponent.offsetTop - (westendHeader ? westendHeader.clientHeight : 0), 500);
      accessibilityFocusHelper.focus(component.reviewTitle);
    };

    const _updateReviewStars = (productId) => {
      app.ajax.get({
        url: '/' + productId + '.reviewstars',
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    const _successHandler = (response) => {
      const parent = component.element.parentNode;
      if (parent !== null) {
        parent.innerHTML = response;
        const parentClasses = [...parent.classList];
        const reviewStarsID = parentClasses.length > 0
            ? parentClasses[0]
            : `productReviewStarsID-${Math.floor((Math.random() * 100000))}`;
        
        const newElement = parent.querySelector(component.config.productReviewStars);
        
        if (newElement) {
          const linearGradientEl = newElement.getElementsByTagName(component.config.svgLinearGradient)[0];
          linearGradientEl && linearGradientEl.setAttribute('id', reviewStarsID);
          
          const pathEl = newElement.getElementsByTagName(component.config.svgPath)[0];
          pathEl && pathEl.setAttribute('fill', `url(#${reviewStarsID})`);
        }
        component.init(newElement, true);
      }
    };


    const _errorHandler = () => {
      console.error(
        'ERROR: Could not retrieve new product review stars');
    };

    component.config = _config;
    component.init = _init;
    component.scrollToReviewsComponent = _scrollToReviewsComponent;
    component.updateReviewStars = _updateReviewStars;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;


    return component;
  };

  return productReviewStars;
});

